<template>
  <div class="map">
    <v-row class="mb-6">
      <v-col
        cols="12"
        md="2">
        <h1> {{ $t('app.page.map.title') }} </h1>
      </v-col>
      <v-col
        cols="12"
        md="2">
        <v-select
          v-model="status"
          :items="statusEnum"
          item-text="text"
          item.value="value"
          label="เลือกสถานะ"
          dense
          outlined
          hide-details>
        </v-select>
      </v-col>
      <v-col
        cols="12"
        md="2">
        <v-select
          v-model="province"
          :items="provinces"
          item-text="text"
          item.value="value"
          label="เลือกจังหวัด"
          dense
          outlined
          hide-details>
        </v-select>
      </v-col>
      <v-col
        cols="12"
        md="2">
        <v-select
          v-model="type"
          :items="typeEnum"
          item-text="text"
          item.value="value"
          label="ประเภทของจุด"
          dense
          outlined
          hide-details>
        </v-select>
      </v-col>
      <v-col
        cols="12"
        md="2">
        <v-select
          v-model="group"
          :items="groups"
          label="เลือกจำนวนจุด"
          dense
          outlined
          clearable
          hide-details>
          <template v-slot:item="{ item }">
            จุดที่ {{ item }}
          </template>
        </v-select>
      </v-col>
      <v-col
        cols="12"
        md="2">
        <div class="d-flex justify-end">
          <v-btn
            height="40"
            color="primary"
            depressed
            @click="getLocation()">
            ค้นหา
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <gmap-map
      v-if="myLocation.lat && myLocation.lng"
      :style="{ 'height': mapHeight + 'px' }"
      :center="{ lat: Number(myLocation.lat), lng: Number(myLocation.lng) }"
      :zoom="12"
      map-type-id="roadmap">
      <gmap-marker
        v-for="(item, i) in items"
        :key="`map-marker-${i}`"
        :position="{ lat: item.latitude , lng: item.longitude}"
        :icon="item.status === 'ACTIVE' ? displayActiveIcon(item) : displayInactiveIcon(item)"
        :clickable="true"
        :draggable="false"
        @click="selectMarker(item.id)">
        <gmap-info-window
          :opened="item.id === selectedMarkerId"
          @closeclick="selectedMarkerId = ''">
          <h3> {{ item.name }} </h3>
        </gmap-info-window>
      </gmap-marker>
      <gmap-marker
        :position="{ lat: myLocation.lat, lng: myLocation.lng }"
        :clickable="false"
        :draggable="false" />
    </gmap-map>
    <div
      v-else
      :style="{ 'height': mapHeight + 'px', 'backgroundColor': '#F4F4F4' }">
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LocationProvider from '@/resources/axios/providers/location'

export default {
  name: 'Map',
  data () {
    return {
      selectedMarkerId: null,
      items: [],
      status: '',
      statusEnum: [
        { text: 'ทั้งหมด', value: '' },
        { text: 'ใช้งานอยู่', value: 'ACTIVE' },
        { text: 'ไม่ได้ใช้งาน', value: 'INACTIVE' }
        // { text: 'จุดคำนวณเอง', value: 'PREDICTION' }
      ],
      group: '',
      groups: [],
      type: '',
      typeEnum: [
        { text: 'ทั้งหมด', value: '' },
        { text: 'กู้ภัย', value: 'rescue' },
        { text: 'รถพยาบาล', value: 'medical' },
        { text: 'ดับเพลง', value: 'fire' }
      ],
      province: '',
      provinces: [
        { text: 'ทั้งหมด', value: '' },
        { text: 'ปราจีนบุรี', value: 'ปราจีนบุรี' },
        { text: 'ชลบุรี', value: 'ชลบุรี' },
        { text: 'ฉะเชิงเทรา', value: 'ฉะเชิงเทรา' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      myLocation: 'local/myLocation'
    }),
    mapHeight () {
      return window.innerHeight - (64 + 48 + 36 + 24 + 32)
    }
  },
  watch: {
    // eslint-disable-next-line object-shorthand
    'myLocation.lat' () {
      this.getLocation()
    }
  },
  mounted () {
    if (this.myLocation.lat && this.myLocation.lng) {
      this.getLocation()
    }
    this.getNumberGroups()
  },
  methods: {
    async getNumberGroups () {
      try {
        const response = await LocationProvider.getNumberGroups()
        this.groups = response
      } catch (error) {
        console.error('ERROR in get number groups', error)
      }
    },
    selectMarker (value) {
      this.selectedMarkerId = value
    },
    displayActiveIcon (value) {
      if (value.type === 'rescue') {
        return 'https://storage.googleapis.com/emergency-location-321611/MapMarker/GPS-Res.png'
      }
      if (value.type === 'medical') {
        return 'https://storage.googleapis.com/emergency-location-321611/MapMarker/GPS-Ambulance.png'
      }
      return 'https://storage.googleapis.com/emergency-location-321611/MapMarker/GPS-Fire.png'
    },
    displayInactiveIcon (value) {
      if (value.type === 'rescue') {
        return 'https://storage.googleapis.com/emergency-location-321611/MapMarker/GPS-Res-AI.png'
      }
      if (value.type === 'medical') {
        return 'https://storage.googleapis.com/emergency-location-321611/MapMarker/GPS-Ambulance-AI.png'
      }
      return 'https://storage.googleapis.com/emergency-location-321611/MapMarker/GPS-Fire-AI.png'
    },
    formatNumber (item) {
      return Number(item)
    },
    async getLocation () {
      try {
        this.isLoading = true
        const res = await LocationProvider.getLocationList({
          page: 1,
          limit: 1000,
          status: this.status || '',
          groups: this.group,
          province: this.province || '',
          type: this.type
        })
        if (res) {
          this.items = res.records
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style scoped>
  .map {
    padding: 16px 32px 32px 32px;
    height: 100%;
  }
</style>
